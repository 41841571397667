.selection-table {
    color: white;
    background-color: #457b9d;
}

.playlist-choice-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #1D3557;
    min-height: 100vh;
}

.title-div {
    text-align: center;
    color: #f1faee;
    min-width: 100vw;
}

.compare-button {
    min-width: 100%;
    margin-bottom: 10px;
    justify-self: flex-end;
}
