.login-div {
    background-color: #1D3557;
    color: #f1faee;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    flex-direction: column;
    text-align: center;
}

@media (max-width: 900px) {
    .login-div {
        display: flex;
        flex-direction: column;
        max-width: 100vh;
        overflow: no-content;
        padding-inline: 1rem;
    }
}

.login-hero-emoji {
    font-size: calc(60px + 5vmin);
}


