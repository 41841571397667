.spotifyButton {
    background: #1DB954;
    padding: 10px 20px 10px 20px;
    border-radius: 40px;
    cursor: pointer;
    color: #f1faee;
}

.spotifyButton:hover {
    background: lightgreen;
}


